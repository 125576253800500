// We need to distinguish animation's name
// because web browser doesn't want to restart it and execute again
// React also will not update view if animation won't be reset

@each $s in $cc-custom-status {
  @keyframes icon-animation-#{$s} {
    @if $s == 'back' {
      0% {
        transform: scaleX(-1);
      }
      100% {
        //opacity: 1;
        transform: scaleX(1);
      }
    } @else {
      0% {
        opacity: 0;
        transform: scale(.5);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

@each $c in $cc-company {
  @keyframes icon-animation-#{$c} {
    0% {
      opacity: 0;
      transform: scale(.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}
