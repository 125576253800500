// Roboto font weights:
// Thin: 100
// Light: 300
// Regular: 400
// Medium: 500
// Bold: 700
// Black: 900
// Not all are included (only needed)
// Download: https://fonts.google.com/specimen/Roboto

@font-face {
  font-family: Roboto300;
  font-display: swap;
  src: url("../fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: Roboto400;
  font-display: swap;
  src: url("../fonts/Roboto-Regular.ttf");
}
