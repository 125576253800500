.component-header {
  margin-top: 10px;
  margin-bottom: 10px;
  flex: 0 0 auto;
}

.component-content {
  margin-top: 10px;
  margin-bottom: 10px;
  flex: 1 0 auto;
}

.component-footer {
  margin-top: 30px;
  margin-bottom: 0;
  flex-shrink: 0;

  .separator {
    border-top: 1px #909090 solid;
    height: 1px;
    width: 100%;
  }

  .footer-elements {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: flex-end;

    #secupay-logo {
      margin-right: auto;

      img {
        height: 20px;
      }
    }

    .footer-links {
      list-style-type: none;
      padding: 0;
      margin-bottom: 0;
      margin-right: 10px;

      .wrapper-link {
        display: inline-block;

        &:not(:last-child) {
          border-right: 1px $color-gray solid;
        }

        a.element-link {
          text-decoration: none;
          color: $color-gray;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 16px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .lang-switcher {
      height: 20px;
    }
  }

  @media (max-width: 575.98px) {
    .footer-elements {
      flex-direction: column;
      justify-content: center;

      .footer-links {
        margin-top: 15px;
        margin-bottom: 15px;
        margin-right: 0;
      }

      #secupay-logo {
        margin-right: unset;
      }
    }
  }
}
