*, *:before, *:after {
  box-sizing: border-box;
  position: relative;
}

.initialization {
  .wrapper-spinner {
    position: fixed;
  }
}

.initialized {
  .wrapper-spinner {
    position: absolute;
  }
}

.wrapper-spinner {
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  z-index: 100;

  .bg-cover {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 102;
    top: 0;
    left: 0;
    opacity: 0.7;
  }

  .spinner {
    width: 100px;
    height: 100px;
    animation: contanim 2s linear infinite;
    //position: fixed;
    //top: 50%;
    //left: 50%;
    //margin-top: -50px;
    //margin-left: -50px;
    z-index: 103;

    svg {
      display: block;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
      transform: rotate(-90deg);

      &:nth-child(1) circle {
        stroke: #868686;
        stroke-dasharray: 1, 300;
        stroke-dashoffset: 0;
        animation: strokeanim 3s calc(.2s * (1)) ease infinite;
        transform-origin: center center;
      }

      &:nth-child(2) circle {
        stroke: #c7c7c6;
        stroke-dasharray: 1, 300;
        stroke-dashoffset: 0;
        animation: strokeanim 3s calc(.2s * (2)) ease infinite;
        transform-origin: center center;
      }

      &:nth-child(3) circle {
        stroke: #7c9c2b;
        stroke-dasharray: 1, 300;
        stroke-dashoffset: 0;
        animation: strokeanim 3s calc(.2s * (3)) ease infinite;
        transform-origin: center center;
      }

      &:nth-child(4) circle {
        stroke: #b9cd13;
        stroke-dasharray: 1, 300;
        stroke-dashoffset: 0;
        animation: strokeanim 3s calc(.2s * (4)) ease infinite;
        transform-origin: center center;
      }
    }
  }
}



@keyframes strokeanim {
  0% {
    stroke-dasharray: 1, 300;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -140;
  }
  100% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -420;
  }
}

@keyframes contanim {
  100% {
    transform: rotate(360deg);
  }
}
