$appMargin: 20px;

.cursor-pointer {
  cursor: pointer;
}

body {
  display: flex;
  justify-content: center;
  font-family: Roboto300;
  font-weight: 300;

  &.custom-background {
    background-size: cover !important;
    background-attachment: fixed !important;
  }

  &.initialized {
    background-color: #eeeeee;
  }

  #pay-secupay-app {
    width: 100%;
    max-width: 900px;
    background: #ffffff;
    padding: 0 20px;
    margin: $appMargin;
    overflow: auto;

    & > div {
      &.initialized {
        padding-top: 20px;
        padding-bottom: 10px;
        height: calc(100vh - 2 * #{$appMargin});
        display: flex;
        flex-direction: column;
      }
    }

    @media (max-width: 575.98px) {
      margin: 0;

      & > div {
        &.initialized {
          height: 100vh;
        }
      }
    }
  }
}
