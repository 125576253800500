// INFO
// In SPPAYIF-1568 and SPPAYIF-1569 we present config:style:bootstrap property
// where you can set and use classes from Bootstrap 5.1
// instead of hardcoding css rules

//Currently "redirect-button" rules are based on "action-button"
.#{$ELEMENT_WRAPPER_PREFIX}-#{$ACTION_BUTTON},
.#{$ELEMENT_WRAPPER_PREFIX}-#{$REDIRECT_BUTTON},
.#{$ELEMENT_WRAPPER_PREFIX}-#{$GOOGLE_PAY_BUTTON},
.#{$ELEMENT_WRAPPER_PREFIX}-#{$APPLE_PAY_BUTTON} {
  text-align: right;
  margin-top: 16px;
  margin-bottom: 10px;

  .#{$ELEMENT_PREFIX}-#{$ACTION_BUTTON},
  .#{$ELEMENT_PREFIX}-#{$REDIRECT_BUTTON} {
    background-color: $color-main-green !important;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    font-family: Roboto400;

    &:hover {
      background-color: $color-main-green-hover !important;
    }

    &:focus {
      outline: 0;
      box-shadow: none;
      // disabled, because it was being clipped
      //box-shadow: 0 0 0 0.25rem rgba(123, 160, 45, .25);
    }
  }
}

.#{$ELEMENT_WRAPPER_PREFIX}-#{$CLOSE_BUTTON} {
  text-align: right;

  .#{$ELEMENT_PREFIX}-#{$CLOSE_BUTTON} {
    cursor: pointer;
  }
}

.#{$ELEMENT_WRAPPER_PREFIX}-#{$HEADLINE} {
  .#{$ELEMENT_PREFIX}-#{$HEADLINE} {
    color: $color-main-green;
    font-size: 24px;
    font-family: Roboto400;
  }
}

.#{$ELEMENT_WRAPPER_PREFIX}-#{$SUB_HEADLINE} {
  .#{$ELEMENT_PREFIX}-#{$SUB_HEADLINE} {
    color: $color-text;
    font-size: 16px;
    font-family: Roboto400;
  }
}

.#{$ELEMENT_WRAPPER_PREFIX}-#{$TEXT} {
  .#{$ELEMENT_PREFIX}-#{$TEXT} {
    color: $color-text;
    font-size: 16px;

    p {
      margin-bottom: 5px;
    }
  }
}

.#{$ELEMENT_WRAPPER_PREFIX}-#{$DEBIT_FORM} {
  .#{$ELEMENT_PREFIX}-#{$DEBIT_FORM} {
    .form-label {
      font-size: 16px;
      color: $color-text;
    }

    input {
      &:focus {
        border-color: $color-main-green !important;
        box-shadow: none;
        //box-shadow: 0 0 0 0.25rem rgba($color-main-green, 0.25) !important;
      }
    }

    .has-errors {
      input {
        border-color: $color-red;

        &:focus {
          border-color: $color-red !important;
          box-shadow: none;
          //box-shadow: 0 0 0 0.25rem rgba($color-red, 0.25) !important;
        }
      }

      .error-message {
        margin-top: 10px;
        color: $color-red;
        font-size: 11px;
      }
    }
  }
}

.#{$ELEMENT_WRAPPER_PREFIX}-#{$CREDIT_FORM} {
  .#{$ELEMENT_PREFIX}-#{$CREDIT_FORM} {

    // smaller font size
    input {
      font-size: 14px;

      &:-webkit-autofill {
        -webkit-text-fill-color: #212529;
        transition: background-color 5000s ease-in-out 0s;
      }
    }

    [name="owner"] {
      border: 1px solid #ced4da; // default input value

      &:focus {
        box-shadow: none;
      }
    }

    .card-data {
      display: flex;
      border: 1px solid #ced4da;
      border-radius: .25rem;

      // rules for all inputs in card-data
      input {
        border: none;
        border-radius: 0;

        &::placeholder {
          font-size: 12px;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .wrapper-card-number {
        flex-grow: 1;

        input {
          padding-left: 44px;
        }

        .cc-icon {
          left: 10px;
          top: 6px;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;

          &.loading {
            animation: none !important;
            display: none;
          }

          &.cc-company {
            border: 1px #ddd solid;
            border-radius: 2px;
            background: #eee;
            padding: $cc-icon-padding;
            width: #{$cc-icon-width + 2};
            height: #{$cc-icon-height + 2};

            @each $c in $cc-company {
              &.#{$c} {
                animation: icon-animation-#{$c} $cc-animation-duration;
              }
            }

            svg {
              max-height: 10px;
            }
          }

          @each $s in $cc-custom-status {
            &.#{$s} {
              border: none;
              animation: icon-animation-#{$s} $cc-animation-duration;

              // because file has no width set
              svg {
                width: $cc-icon-width;
              }
            }
          }

          // general rules for each svg
          svg {
            display: block;
            height: auto;
          }
        }
      }

      .wrapper-exp-date {
        // rules to keep fixed width in flex
        min-width: 66px;
        flex-basis: 0;

        input {
          padding-right: .5rem;
        }
      }

      .wrapper-cvc {
        min-width: 50px; // display 4 digits (e.g. AMEX)
        flex-basis: 0;

        input {
          padding-left: .5rem;
        }
      }
    }

    .has-errors {
      & > input {
        color: $color-red;
      }

      // currently hidden
      // (display only general card data error message)
      .error-message {
        margin-top: 10px;
        color: $color-red;
        font-size: 11px;
      }
    }
  }
}

.#{$ELEMENT_WRAPPER_PREFIX}-#{$DYNAMIC_FORM} {
  .#{$ELEMENT_PREFIX}-#{$DYNAMIC_FORM} {
    .has-errors {
      input {
        border-color: red;
      }

      .error-message {
        margin-top: 10px;
        color: $color-red;
        font-size: 11px;
        margin-bottom: 0;
      }
    }
  }
}

.#{$ELEMENT_WRAPPER_PREFIX}-#{$DYNAMIC_FORM} {
  .#{$ELEMENT_PREFIX}-#{$DYNAMIC_FORM} {
    .form-field-wrapper {
      margin-top: 20px;
    }
  }
}

.#{$ELEMENT_WRAPPER_PREFIX}-#{$AMOUNT} {

  .#{$ELEMENT_PREFIX}-#{$AMOUNT} {
    font-size: 16px;
    color: $color-amount;
  }
}

.#{$ELEMENT_WRAPPER_PREFIX}-#{$TABLE} {
  margin-top: 10px;
  margin-bottom: 10px;

  .#{$ELEMENT_PREFIX}-#{$TABLE} {
    table {
      width: auto;
      margin-bottom: 0;

      td {
        border-bottom: none;
        padding: 2px 8px;
        color: $color-text;

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
}

.#{$ELEMENT_WRAPPER_PREFIX}-#{$QR_CODE} {
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: center;

  .#{$ELEMENT_PREFIX}-#{$QR_CODE} {
  }
}

// CONTAINER element has no wrapper
.#{$ELEMENT_PREFIX}-#{$CONTAINER} {
}

// IMAGE element has no wrapper
.#{$ELEMENT_PREFIX}-#{$IMAGE} {
}

.#{$ELEMENT_WRAPPER_PREFIX}-#{$CONTAINER} {

  .#{$ELEMENT_PREFIX}-#{$CONTAINER} {
  }
}

.#{$ELEMENT_WRAPPER_PREFIX}-#{$ACCORDION} {
  .#{$ELEMENT_PREFIX}-#{$ACCORDION_SUB_ELEMENT} {
    margin-bottom: 10px;

    // currently "expanded" means selected
    &.expanded {

      .header {
        background: $color-accordion-header;
      }

      .content {
        // max-height is set directly in AccordionElement
        transition: max-height .2s ease-in;
      }
    }

    &.collapsed {

      .content {
        transition: max-height .2s ease-out;
        overflow: hidden;
      }
    }

    .header {
      height: 40px;
      border: 1px #ddd solid;
      padding: 4px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      cursor: pointer;

      .icons {
        display: flex;
        align-items: center;

        .payment-method-icon, .cc-icon {
          margin-right: 6px;
        }

        svg {
          height: auto;
          width: auto;
          max-height: 25px;
          max-width: 130px;
          display: block;
        }

        //cc icons should be smaller
        .cc-icon {
          svg {
            max-height: 15px;
            max-width: 70px;
          }

          @media (max-width: 450px) {
            margin-right: 4px;

            svg {
              max-width: 50px;
              max-height: 10px;
            }
          }
        }
      }

      .name {
        display: inline-block;
      }
    }

    .content {
      padding: 0;
      overflow: hidden;
    }
  }
}

.#{$ELEMENT_WRAPPER_PREFIX}-#{$APPLE_PAY_BUTTON} {
  .#{$ELEMENT_PREFIX}-#{$APPLE_PAY_BUTTON} {
    width: 90px;
    height: 30px;
    border-radius: 5px;
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-position: 50% 50%;
    background-color: black;
    background-size: 60%;
    background-repeat: no-repeat;
  }
}
